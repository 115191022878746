video,
img {
    max-width: 100%;
    height: auto;
    vertical-align: bottom;
}

.media-video {
	padding-top: 56.25%;
	position: relative;    


    video,
	iframe,
	object,
	embed {
		position: absolute;
		top: 50%;
		bottom: 50%;
		left: 50%;
		right: 50%;
		
		// transform: translateX(-50%);
		// width: 100%;
		// height: 100%;

		transform: translate(-50%, -50%);
		width: auto;
		height: 100%;
	}
}

.media-poster-mobile {
    padding: $vr*11 0 0;
}

.media-image {
	picture,
	img {
		width: 100%;
		height: auto;
	}
}