.signup-form-container {
    position: relative;
    overflow: hidden;

    max-width: 600px;
    margin: 0 auto;
    // margin-top: 160px;

    position: absolute;
    top: 170px;
    // right: 200px;
    left: 595px;
}

.form-bg {
    position: absolute;
    top: 0;
    left: 0;
}

.form-copy {
    display: none;
    margin-top: $vr*22;

    .section-form & {
        display: block;
    }
}

.signup-form-content {
    position: relative;
    // display: none;
    transition: all 300ms ease-in-out;

    // &.signup-form-content-in {
    //     display: block;
    // }

    // &.signup-form-content-show {
    // }
}

.signup-form {
    position: relative;
    // background-color: $white;
    border-radius: 20px;
    // padding: 20px;
}

.signup-intro {}
.signup-form {}
.signup-signup-success {}
