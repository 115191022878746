* { box-sizing: border-box; }

$gutter-width: 0;
$container-width: $max-width;
$grid-columns: 4;
$susy: (
	global-box-sizing: border-box,
	columns: susy-repeat($grid-columns),
	gutters: $gutter-width,
	spread: wide,
  	container-spread: wide
);

@mixin grid($from: 1, $to: $grid-columns){
	@for $i from $from through $to {
		.g_#{$i}{
			float: left;
			width: span(4);

			@media #{$BPD} {
				width: span($i of $grid-columns);
				padding-right: gutter();
			}
		}

		.g_#{$i}_wider {
			float: left;
			width: span(4 wider);
			padding-right: 0;
			margin-left: -$ggw;

			@media (min-width: #{$breakpoint-b}rem) and (max-width: #{$breakpoint-e}rem) {
				width: span(2 wide of 4 narrow);
				padding-right: gutter();
				margin-left: 0;

				&:nth-child(2n) {
					width: span(2 narrow of 4 narrow);
					padding-right: 0;
				}
			}

			@media #{$BPE} {
				width: span($i wide of 4 narrow);
				padding-right: gutter();
				margin-left: 0;

				&:last-of-type {
					width: span($i narrow of 4 narrow);
					padding-right: 0;
				}
			}			
		}
	}
}

@include grid();

.row {
	@extend .clearfix;
}

// .grid-container {
// 	// background: susy-svg-grid() no-repeat scroll; // for testing
// 	width: 100%;
// 	max-width: $max-width;
// 	margin: 0 auto;
// 	padding: 0 $ggw;
// 	@extend .clearfix;
// }

.grid-container {
	// background: susy-svg-grid() no-repeat scroll; // for testing
	width: 100%;
	max-width: $max-width;
	margin: 0 auto;
	padding: 0;
	@extend .clearfix;
}

.eq-wrapper {
	display: flex;
	flex-wrap: wrap;
}

.eq-height {
	display: flex;
	flex-direction: column;
}
