.btn {
    // font-size: 14px;
    // font-weight: 700;
    letter-spacing: 1px;

    padding: $vr*2 $ggw;
    padding: $vr*2 $ggw/2;
    border-radius: 8px;
    border-width: 1px;
    border-style: solid;
    border-color: $brand_color_a;
    background-color: $white;
    color: $brand_color_a;
    transition: border-color 300ms ease-in-out, color 300ms ease-in-out;

    text-decoration: none;
}



.btn-restart {
    background-color: $brand_color_a;
    color: $white;

    display: none;

    text-decoration: none !important;
    min-width: 200px;

    .signup-success & {
        display: inline-block;
        position: absolute;
        bottom: $vr*5;
        left: 50%;
        transform: translateX(-50%);
    }
}