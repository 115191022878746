body {
    background-color: $white;
        // content: '';
        // display: block;
        // height: 100vh;
        // width: 100vw;
        
}

.main {
    position: relative;
    height: 100%;
    height: 100vh;
    width: 100vw;
    // overflow: hidden;

    background-image: url('/images/png/background_full.png');
    background-size: cover;
    background-repeat: no-repeat;            
    // z-index: -1;
    // .panel-2 & {
    //     background-image: url('/images/jpg/screens-02.jpg');
    // }    
}
