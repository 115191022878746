$keyboard_colour_a: #282828;
$keyboard_colour_b: #484848;
$keyboard_colour_c: #404040;
$keyboard_colour_d: #444444;
$keyboard_colour_e: #333333;
$keyboard_colour_h: #4f4f4f;
$keyboard_colour_i: #5f5f5f;
$keyboard_colour_j: #555555;
$keyboard_colour_g: #dddddd;
$keyboard_colour_f: #eeeeee;

$keyboard_colour_a: darken($white, 11%);
$keyboard_colour_b: darken($white, 10%);
$keyboard_colour_c: darken($white, 10%);
$keyboard_colour_d: darken($white, 10%);
$keyboard_colour_e: darken($white, 10%);
$keyboard_colour_h: darken($white, 10%);
$keyboard_colour_i: darken($white, 10%);
$keyboard_colour_j: darken($white, 10%);
$keyboard_colour_g: darken($white, 10%);
$keyboard_colour_f: darken($white, 50%);

.ui-keyboard {
    font-size: 14px;
    text-align: center;
    background: $keyboard_colour_a;
    border: 1px solid $keyboard_colour_b;
    padding: 50px;
    width: 100%;
    height: auto;
    left: 0;
    top: auto;
    bottom: 0;
    position: fixed;
    white-space: nowrap;
    overflow-x: auto;
    -ms-touch-action: manipulation;
    touch-action: manipulation
}

.ui-keyboard-has-focus {
    z-index: 16001
}

.ui-keyboard-button {
    border: 1px solid $keyboard_colour_c;
    padding: 0 .5em;
    margin: 3px;
    min-width: 3em;
    height: 3em;
    line-height: 3em;
    vertical-align: top;
    font-family: Helvetica, Arial, sans-serif;
    color: $keyboard_colour_f;
    text-align: center;
    border-radius: 5px;
    -webkit-box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, .5);
    box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, .5);
    background: $keyboard_colour_d;
    // background-image: -webkit-linear-gradient(-90deg, $keyboard_colour_d 0, $keyboard_colour_e 100%);
    // background-image: linear-gradient(-90deg, $keyboard_colour_d 0, $keyboard_colour_e 100%);
    cursor: pointer;
    overflow: hidden;
    -moz-user-focus: ignore;

    @media #{$BPG}{
        min-width: 4em;
        height: 4em;
        line-height: 4em;
        margin: 5px;
    }
}

.ui-keyboard-button:not([disabled]):hover {
    background: $keyboard_colour_a;
    // background-image: -webkit-linear-gradient(-90deg, $keyboard_colour_h 0, $keyboard_colour_d 100%);
    // background-image: linear-gradient(-90deg, $keyboard_colour_h 0, $keyboard_colour_d 100%)
}

.ui-keyboard-button:not([disabled]):active {
    background: $keyboard_colour_g;
    // background-image: -webkit-linear-gradient(-90deg, $keyboard_colour_j 0, $keyboard_colour_i 100%);
    // background-image: linear-gradient(-90deg, $keyboard_colour_j 0, $keyboard_colour_i 100%)
}

.ui-keyboard-button span {
    display: block;
    width: 100%;
    font-size: 1.2em;
    text-align: center
}

.ui-keyboard-actionkey:not(.ui-keyboard-dec):not(.ui-keyboard-combo) {
    min-width: 6em;

    @media #{$BPG}{
        min-width: 9em;
    }    
}

.ui-keyboard-space {
    width: 15em;

    @media #{$BPG}{
        width: 30em;
    }
}

.ui-keyboard-actionkey:not(.ui-keyboard-dec):not(.ui-keyboard-combo) span {
    font-size: .8em;
    position: relative;
    top: -1em;
    left: -1.6em
}

.ui-keyboard-placeholder {
    color: #888
}

.ui-keyboard-nokeyboard {
    color: #888;
    border-color: #888
}

.ui-keyboard-spacer {
    display: inline-block;
    width: 1px;
    height: 0;
    cursor: default
}

.ui-keyboard-LRM span,
.ui-keyboard-NBSP span,
.ui-keyboard-RLM span,
.ui-keyboard-ZWJ span,
.ui-keyboard-ZWNJ span,
.ui-keyboard-ZWSP span {
    font-size: .5em;
    line-height: 1.5em;
    white-space: normal
}

.ui-keyboard-button.ui-keyboard-combo.ui-state-default {
    -webkit-box-shadow: 1px 1px 3px 0 rgba(213, 133, 18, .5);
    box-shadow: 1px 1px 3px 0 rgba(213, 133, 18, .5);
    border-color: #d58512;
}

.ui-keyboard-button.ui-keyboard-combo.ui-state-active {
    -webkit-box-shadow: 1px 1px 3px 0 rgba(38, 154, 188, .5);
    box-shadow: 1px 1px 3px 0 rgba(38, 154, 188, .5);
    border-color: #269abc;
}

button.ui-keyboard-accept.ui-keyboard-valid-input {
    -webkit-box-shadow: 1px 1px 3px 0 rgba(57, 132, 57, .5);
    box-shadow: 1px 1px 3px 0 rgba(57, 132, 57, .5);
    border-color: #398439;
}

button.ui-keyboard-accept.ui-keyboard-valid-input:not([disabled]):hover {
    border-color: #4cae4c;
}

button.ui-keyboard-accept.ui-keyboard-invalid-input {
    -webkit-box-shadow: 1px 1px 3px 0 rgba(172, 41, 37, .5);
    box-shadow: 1px 1px 3px 0 rgba(172, 41, 37, .5);
    border-color: #ac2925;
}

button.ui-keyboard-accept.ui-keyboard-invalid-input:not([disabled]):hover {
    border-color: #d43f3a;
}

button.ui-keyboard-toggle span {
    width: .9em;
    height: .9em;
    display: inline-block;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMCIgaGVpZ2h0PSIxMCIgZmlsbD0iI2VlZSI+PHBhdGggZD0iTTguNyw0LjRINy41SDUuMHYtMS45YzAtMS40LTEuMS0yLjUtMi41LTIuNWMtMS40LDAtMi41LDEuMS0yLjUsMi41djEuOWgxLjIgdi0xLjljMC0wLjcsMC42LTEuMiwxLjItMS4yczEuMiwwLjYsMS4yLDEuMnYxLjljLTAuNywwLTEuMiwwLjYtMS4yLDEuMlY4LjggYzAsMC43LDAuNiwxLjIsMS4yLDEuMmg1LjBDOS40LDEwLDEwLDkuNCwxMCw4LjhWNS42QzEwLDUuMCw5LjQsNC40LDguOCw0LjR6IE02LjYsNy40djEuMCBjMCwwLjItMC4xLDAuMy0wLjMsMC4zUzYuMCw4LjYsNi4wLDguNFY3LjRjLTAuMi0wLjEtMC4zLTAuMy0wLjMtMC41YzAtMC4zLDAuMy0wLjYsMC42LTAuNiBTNi45LDYuNiw2LjksNi45QzYuOSw3LjEsNi44LDcuMyw2LjYsNy40eiIvPjwvc3ZnPg==)
}

button.ui-keyboard-toggle.ui-keyboard-disabled span {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMCIgaGVpZ2h0PSIxMCIgZmlsbD0iI2VlZSI+PHBhdGggZD0iTTcuNCA0LjRWMi41YzAtMS40LTEuMS0yLjUtMi41LTIuNWMtMS40IDAtMi41IDEuMS0yLjUgMi41djEuOSBjLTAuNyAwLTEuMiAwLjUtMS4yIDEuMnYzLjFDMS4zIDkuNCAxLjggMTAgMi41IDEwaDQuOWMwLjcgMCAxLjItMC42IDEuMi0xLjJWNS42IEM4LjcgNC45IDguMSA0LjQgNy40IDQuNHogTTUuMyA3LjR2MS4wYzAgMC4yLTAuMSAwLjMtMC4zIDAuM2MtMC4yIDAtMC4zLTAuMS0wLjMtMC4zVjcuNCBjLTAuMi0wLjEtMC4zLTAuMy0wLjMtMC41YzAtMC4zIDAuMy0wLjYgMC42LTAuNmMwLjMgMCAwLjYgMC4zIDAuNiAwLjYgQzUuNiA3LjEgNS41IDcuMyA1LjMgNy40eiBNNi4yIDQuNEgzLjdWMi41YzAtMC43IDAuNS0xLjIgMS4yLTEuMmMwLjcgMCAxLjIgMC42IDEuMiAxLjIgVjQuNHoiLz48L3N2Zz4=)
}

.ui-keyboard.ui-keyboard-disabled button:not(.ui-keyboard-toggle),
.ui-keyboard.ui-keyboard-disabled input {
    opacity: .5
}

.ui-keyboard-overlay {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(0, 0, 0, .5)
}

.ui-keyboard-popup {
    display: inline-block;
    max-width: 22em
}

.ui-keyboard.ui-keyboard-popup-open .ui-keyboard-keyset .ui-keyboard-button {
    pointer-events: none
}

.ui-keyboard-caret {
    background: #c00;
    width: 1px;
    margin-top: 3px
}

div.ui-keyboard-extender {
    margin-left: 5px;
    margin-right: 10px
}

button.ui-keyboard-extender span {
    width: .9em;
    height: .9em;
    display: inline-block;
    margin-bottom: 3px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgc3R5bGU9ImZpbGw6I2VlZSI+PGc+PHBhdGggc3R5bGU9ImZpbGw6bm9uZTtzdHJva2U6I2VlZSIgZD0iTSAwLjUsNC41IDE1LjUsNC41IDE1LjUsMTUuNSAwLjUsMTUuNSBaIj48L3BhdGg+PHJlY3Qgd2lkdGg9IjIiIGhlaWdodD0iMiIgeD0iMiIgeT0iNiI+PC9yZWN0PjxyZWN0IHdpZHRoPSIyIiBoZWlnaHQ9IjIiIHg9IjUiIHk9IjYiPjwvcmVjdD48cmVjdCB3aWR0aD0iMiIgaGVpZ2h0PSIyIiB4PSI4IiB5PSI2Ij48L3JlY3Q+PHBhdGggZD0ibSAxMSw2IDMsMCAwLDUgLTIsMCAwLC0zIC0xLDAgeiI+PC9wYXRoPjxyZWN0IHdpZHRoPSIyIiBoZWlnaHQ9IjIiIHg9IjEyIiB5PSIxMiI+PC9yZWN0PjxyZWN0IHdpZHRoPSI2IiBoZWlnaHQ9IjIiIHg9IjUiIHk9IjEyIj48L3JlY3Q+PHJlY3Qgd2lkdGg9IjIiIGhlaWdodD0iMiIgeD0iOSIgeT0iOSI+PC9yZWN0PjxyZWN0IHdpZHRoPSIyIiBoZWlnaHQ9IjIiIHg9IjYiIHk9IjkiPjwvcmVjdD48cmVjdCB3aWR0aD0iMiIgaGVpZ2h0PSIyIiB4PSIyIiB5PSIxMiI+PC9yZWN0PjxyZWN0IHdpZHRoPSIzIiBoZWlnaHQ9IjIiIHg9IjIiIHk9IjkiPjwvcmVjdD48L2c+PC9zdmc+)
}

.ui-keyboard-preview {

    max-width: 770px;

    @media #{$BPG}{
        max-width: 1320px;
        font-size: 40px;
        margin: $vr*2 0;    
    }
}