.hidden-desktop {
    display: none;

    @media #{$BPD_max} {
        display: block;
    }
}

.hidden-mobile {
    display: block;

    @media #{$BPD_max} {
        display: none;
    }
}