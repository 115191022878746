/*-----------------------------------------------*

Author: Peter Broom
Email: me@peterbroom.co.uk

Notes:
This website uses the Susy grid system
http://www.http://oddbird.net/

*-----------------------------------------------*/

// NPM
@import "../../../node_modules/susy/sass/susy";
@import '../../../node_modules/susy/sass/susy-prefix';
@import '../../../node_modules/susy/sass/plugins/svg-grid';
@import '../../../node_modules/virtual-keyboard/dist/css/keyboard.min';
@import '../../../node_modules/fullpage.js/dist/jquery.fullpage';

// Variables
@import "vars/colors";
@import "vars/rythms";
@import "vars/typography";
@import "vars/media-queries";

// Utilities
@import "utilities/mixins";
@import "utilities/breakpoints";
@import "utilities/misc";

// Global
@import "global/reset";
@import "global/layout";
@import "global/grid";
@import "global/typography";
@import "global/brand";
@import "global/links";
@import "global/lists";
@import "global/buttons";
@import "global/media";
@import "global/forms";
@import "global/tables";

// Third party
@import "thirdparty/keyboard";

// Components
@import "components/header";
@import "components/navigation";
@import "components/headings";
@import "components/panels";
// @import "components/footer";
@import "components/signup-form";
@import "components/keyboard";
@import "components/slides";
@import "components/modal";
@import "components/lock";
@import "components/hotspots";

