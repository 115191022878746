.modal {
    display: none;
    opacity: 0;
    transition: all 300ms ease-in-out;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -30%);

    min-width: 1000px;
    min-height: 1px;

    z-index: 9999;

    .modal-in & {
        display: block;
    }

    .modal-show & {
        opacity: 1;
        transform: translate(-50%, -50%);
    }

    text-align: center;
    h2 {
        color: $brand_color_a;
        font-weight: 700;
        text-transform: capitalize;
    }
}

.modal-inner {
    position: relative;
    padding: $vr*6 $ggw $vr*6 $ggw;
    background-color: $white;
    border-radius: 20px;

    min-height: 400px;

    .modal-layout-b & {
        text-align: center;
        background-color: transparent;
    }
}

.modal-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    font-size: 30px;
}

.modal-close {
    position: absolute;
    top: $vr*3;
    right: $ggw;

    background-image: url('../images/svg/close.svg');
    background-repeat: no-repeat;
    background-size: contain;

    width: 40px;
    height: 40px;

    z-index: 9999;

    .signup-success &,
    .modal-layout-b & {
        display: none;
    }

    span {
        visibility: hidden;
        text-indent: -9999px;
    }
}

.modal-overlay {
    display: none;
    opacity: 0;
    transition: opacity 300ms ease-in-out;

    position: fixed;
    width: 100%;
    top: 0; right: 0; bottom: 0; left: 0;
    background-color: rgba(0,0,0,0.5);
    z-index: 9998;

    .modal-in & {
        display: block;
    }

    .modal-show & {
        opacity: 1;
    }

    .modal-layout-b & {
        background-color: rgba(255,255,255,0.9);        
    }
}
