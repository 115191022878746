.navigation-list {
    // position: relative;
    position: absolute;
    top: 150px;
}

.navigation-item a {
    position: absolute;
    display: block;
    width: 100px;
    height: 100px;

    opacity: 0;

    &:nth-child(1){
        top: 0;
        left: 90px;

        @media #{$BPG}{
            top: 70px;
            left: 130px;    
        }
    }

    &:nth-child(2){
        top: 60px;
        left: 150px;

        @media #{$BPG}{
            top: 160px;
            left: 240px;
        }
    }
    &:nth-child(3){
        top: 120px;
        left: 90px;

        @media #{$BPG}{
            top: 260px;
            left: 130px;
        }
    }
    &:nth-child(4){
        top: 190px;
        left: 150px;

        @media #{$BPG}{
            top: 350px;
            left: 240px;
        }
    }
    &:nth-child(5){
        top: 260px;
        left: 90px;

        @media #{$BPG}{
            top: 450px;
            left: 130px;
        }
    }
    &:nth-child(6){
        top: 330px;
        left: 150px;

        @media #{$BPG}{
            top: 550px;
            left: 240px;
        }
    }

}