.panel-container {

}
.panel {
    height:100vh;
    // border: 3px solid red;
//     height: 100vh;
//     width: 100vw;
//     position: absolute;
//     top: 0; right: 0; bottom: 0; left: 0;

//     // background-color: #f1f1f1;

    display: none;
//     transition: opacity 300ms ease-in-out;

    &.panel-in {
        display: block;
    }

    z-index: 1;
}

.panel-inner {
    position: relative;
    height: 100%;
}

.panel-header {
    position: absolute;
    top: $vr*5;
    left: $vr*5;
}

.graph-video {
    background: $white;
    position: absolute;
    top: 160px;
    left: 320px;
    width: 100%;
    max-width: 680px;

    overflow: hidden;

    @media #{$BPG}{
        max-width: 900px;
        top: 255px;
        left: 490px;
    }

    // height: 465px !important;


    // display: none;


    // width: 200px;
    // height: 300px;
    

    iframe {
    // width: 100%;
        // width: 800px;
        // height: 280px;
            // width: 300px;
    }
}

.panel-video-container {
    // height: 100%;
    // position: fixed;
    // position: absolute;
    // top: 0; right: 0; bottom: 0; left: 0;
}

// .panel-hotspots {
//     position: absolute;
//     top: 0;
//     left: 0;
//     width: 100%;
//     height: 100%;
//     display: none;
//     transition: opacity 300ms ease-in-out;
//     z-index: 9999;

//     &.in {
//         display: block;
//     }
// }

// .panel-action {
//     position: absolute;
//     bottom: $vr;
//     right: $ggw;
// }

.signup-modal {
    // top: 50%;
    // left: 0%;
    // left: 350px;
    // transform: translateY(-50%);
    // transform: translate(100%, -50%);    
}

// .panels .panel-content {
//     text-indent: -9999px;
//     visibility: hidden;
// }

.main-content {
    position: relative;
    z-index: 9999;
}

// .panel-container {
//     display: none;

//     .session-start & {
//         display: block;
//     }
// }

// .menu-screen {
//     display: none;

//     .menu-in & {
//         display: block;
//     }
// }

// .intro-screen {
//     display: block;
//     z-index: 9999;

//     .session-start &,
//     .menu-in & {
//         display: none;
//     }
// }

// .hotspot-a {
//     top: 50%;
//     left: 0%;
//     left: 50px;
//     transform: translateY(-50%);
//     // transform: translate(20%, -50%);
// }

// .hotspot-b {
//     top: 50%;
//     left: 50%;
//     left: 50px;
//     transform: translateY(-50%);
//     // transform: translate(-50%, -50%);
// }

// .hotspot-c {
//     top: 50%;
//     left: auto;
//     right: 0%;
//     // transform: translate(-20%, -50%);
//     transform: translateY(-50%);
// }


// .next {
//     // position: absolute;
//     // bottom: $vr*3;
//     // right: $ggw;
//     // display: none;
//     z-index: 9999;

//     .in & {
//         display: block;
//     }
// }


// .prev {
//     position: absolute;
//     bottom: $vr*3;
//     left: $ggw;
//     // display: none;
//     z-index: 9999;

//     .in & {
//         display: block;
//     }
// }

// .panel-header {
//     position: fixed;
//     top: $vr;
//     right: $ggw*2;
//     z-index: 9999;
//     text-align: right;
// }
// .action-home {
//     margin-right: 10px;
//     // background-color: red;
// }

// .content-image {
//     .panel-2 & {
//         opacity: 0;
//     }
// }

.user-view {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 8000;
}

.data_results {
    display: none;
    padding: 10px;
    background-color: rgba(22,228,1,0.5);    
    color: $black;
    font-size: 12px;

    position: fixed;
    bottom: $vr*2;
    left: $ggw/2;

    p {
        margin-bottom: 0;
        font-weight: 700;
    }
}

.data_tracking_id {
    
}


.data_journey {

}


