body {
    font-family: $base_font;
    font-size: 18px;
    line-height: 1.45;
}

h1 {
    text-align: center;
}

h1, h2, h3, h4 {
    text-transform: uppercase;
}

$sizes: (
  h1: (50.889, 1.2em, 100, 40, tera),
  h2: (50.889, 1.2em, 100, 20, alpha),
  h3: (35.989, 1.2em, 100, 20, beta),
  h4: (25.45, 1.6em, 100, 20, gamma),
  h5: (16, 1.6em, 100, 30, delta),
  h6: (12, 1.6em, 100, 30, epsilon),
  p: (12, 1.6em, 100, 30, zeta),
  ul: (12, 1em, 100, 30, zeta),
  small: (16, 0.8em, 100, 20, eta),
  xsmall: (12.73, 0.8em, 100, 20, theta)
);
   
@each $name, $size in $sizes {
    $font_size: nth($size, 1);
    $line_height: nth($size, 2);
    $font_weight: nth($size, 3);
    $margin_bottom: nth($size, 4);
    $greek_alpha: nth($size, 5);
        
    &#{$name} {
        letter-spacing: 1px;
        font-weight: $font_weight;
        margin: 0 0 #{$margin_bottom}px 0;

        font-size: #{$font_size}px;
        line-height: calc(#{$line_height} + 0.7vw);
    }

    %#{$greek_alpha} {
        font-size: #{$font_size}px;        
        line-height: calc(#{$line_height} + 0.5vw);
    }
}
