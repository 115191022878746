input {
    padding: $vr+($vr/2) $ggw;
    border: none;
    // border-radius: 0;
    border-color: $white;
    -webkit-appearance: none;
    width: 100%;
}

::-webkit-input-placeholder {
    color: $brand_color_d;
    font-weight: 100;
}

label {
    color: $white;
    font-size: 14px;
    margin-bottom: $vr;
}

button[type="submit"],
input[type="submit"] {
    margin-top: $vr*3;
}

input[type="number"] {
    padding: $vr 0px;
}

input,
textarea,
select {
    // background-color: #ccc;
}

textarea {
    padding: $vr*3 $ggw;
}

input[type="text"],
input[type="email"] {
	text-align: center;
	letter-spacing: 2px;
}

::-webkit-input-placeholder {
	color: $white;
}

input[type="checkbox"],
input[type="radio"] {
	width: 18px;
	height: 18px;
	padding: 0;
	margin: 0 10px 3px 0;
	outline: 0;
	line-height: normal;
	cursor: pointer;
	background-color: rgba(255, 255, 255, 0.6);
	border: 1px solid rgba(255, 255, 255, 0.8);
	transition: border-color 100ms;
	position: relative;
	display: inline-block;
	vertical-align: top;
	appearance: none;

	border-radius: 50%;

	vertical-align: bottom;

	.lt-ie10 & {
		border: 0;
	}

	&::-moz-focus-inner {
		border: 0;
		padding: 0;
	}

	&:hover,
	&:focus {
		border-color: $white;
	}

	&[disabled] {
		background: #f0f0f0;
		border-color: #ccc;
		color: #ccc;
		cursor: not-allowed;

		&:hover,
		&:focus {
			border-color: $black;
		}
	}

	&[readonly] {

		&:hover,
		&:focus {
			border-color: $black;
		}
	}
}

input[type="checkbox"] {
	// border-radius: 0;
	position: relative;

	&:after {
		content: '';
		position: absolute;
		top: 25%;
		left: 25%;
		transform: translate(-50% -50%);
		// width: 100%;
		// height: 100%;
		// line-height: 100%;
		text-align: center;
		// font-size: 14px;
		// content: "\2714";
		height: 7px;
		width: 7px;

		background-color: $white;
		color: $black;
		display: none;
	}

	&:checked{

		&:after {
			display: block;
		}
	}

	&[disabled] {

		&:after {
			color: #ccc;
		}
	}

	.lt-ie9 & {
		border: 0!important;
	}
}

input[type="radio"] {
	border-radius: 100%;

	&:after {
		content: '';
		position: absolute;
		top: 9px;
		left: 9px;
		width: 0;
		height: 0;
		background: #333;
		border-radius: 100%;
		box-shadow: 0 1px 2px rgba(0,0,0,0.2);
		transition: all 100ms;
	}

	&:checked {
		&:after {
			width: 8px;
			height: 8px;
			top: 4px;
			left: 4px;
		}
	}

	&[disabled] {

		&:after {
			background: #ccc;
		}
	}
	.lt-ie9 & {
		border: 0!important;
	}
}


.custom-select-wrapper {
    position: relative;
    display: inline-block;

    &:before {
        content: '';
        position: absolute;
        right: $ggw/2;
        top: 50%;
        display: block;
        width: 16px;
        height: 16px;
        color: $brand_color_a;

        background-image: url('images/svg/select-arrow.svg');
        background-repeat: no-repeat;

        transform: translateY(-50%);
    }

    .custom-select {
        display: inline-block;
        padding: $vr*2 $ggw*2 $vr*2 $ggw;
        -webkit-appearance: none;
        border-radius: 0px;
        border: 0;

        option {
            // display: none;
        }
    }

    a {
        position: absolute;
        top: 50%;
        left: 100%;
        transform: translateY(-50%);
        display: block;
    }
}

fieldset {
    border: 0;
    padding: 0;
    margin: 0;
}

.form-group {
    margin-bottom: $vr*3;
}

.form-label {
    display: block;
	width: 100%;
	font-size: 24px;
}

.form-label-small {
	font-size: 12px;
}

.form-label-after {
    display: inline-block;
	width: auto;

	// input[type="radio"],
	// input[type="checkbox"] {
	// 	margin-right: 10px;
	// 	vertical-align: middle;
	// }
}

.form-elem {
    border: 1px solid $brand_color_a;
    border-radius: 13px;
	background-color: rgba(255, 255, 255, 0.6);    
	color: $white;
}

.form-input-text {
}
.form-action {}