.table {
    border-collapse: collapse !important;
    border: 0;

    th,
    td {
        padding: $vr*2 $ggw;        
        border: none !important;        
    }
}


.table_style-a {

    tr {
        &:nth-child(odd) {
            background-color: lighten($brand_color_a, 15%);
        }

        &:nth-child(even) {
            background-color: lighten($brand_color_a, 10%);
        }
    }

    th {
        text-align: left;
        background-color: lighten($brand_color_a, 10%);
    }

    td.actions {
        padding: $vr*3 0; 
    }
}

.table_style-b {

    thead {
        th {
            background-color: lighten($brand_color_a, 10%);            
        } 
    }

    tr {
        &:nth-child(odd) {
            background-color: lighten($brand_color_a, 15%);
        }

        &:nth-child(even) {
            background-color: lighten($brand_color_a, 10%);
        }

        .cart-subtotal {
            th {
                background-color: lighten($brand_color_a, 10%);                
            }
        }
    }

    th {
        text-align: left;
    }

    td.actions {
        padding: $vr*3 0; 
    }
}

.table_style-c {

    th,
    td {
        padding: $vr*2 0;
    }

    th {
        text-align: left;
    }
}