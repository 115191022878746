/*
 mobile portrait    30em
 mobile landscape   37.5em
 tablet             48em
 desktop            64em
*/

$breakpoint-a : 20; // 320px
$breakpoint-b : 30; // 480px
$breakpoint-c : 37.500; // 600px
$breakpoint-d : 48; // 768px
$breakpoint-e : 61.250; // 980px
$breakpoint-f : 73.750; // 1180px
$breakpoint-g : 91.875; // 1470px
$breakpoint-h : 120.000; // 1920px

$max-width : #{$breakpoint-h}rem;

// For break point shorthands
$BPA: "(min-width: #{$breakpoint-a}rem)";
$BPB: "(min-width: #{$breakpoint-b}rem)";
$BPC: "(min-width: #{$breakpoint-c}rem)";
$BPD: "(min-width: #{$breakpoint-d}rem)";
$BPE: "(min-width: #{$breakpoint-e}rem)";
$BPF: "(min-width: #{$breakpoint-f}rem)";
$BPG: "(min-width: #{$breakpoint-g}rem)";

$BPA_max: "(max-width: #{$breakpoint-a}rem)";
$BPB_max: "(max-width: #{$breakpoint-b}rem)";
$BPC_max: "(max-width: #{$breakpoint-c}rem)";
$BPD_max: "(max-width: #{$breakpoint-d}rem)";
$BPE_max: "(max-width: #{$breakpoint-e}rem)";
$BPF_max: "(max-width: #{$breakpoint-f}rem)";
$BPG_max: "(max-width: #{$breakpoint-g}rem)";