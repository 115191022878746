body {
    background-color: $white;
    color: $black;
}

h1, h2, h3, h4, h5, h6 {
    color: $black;
}


p {
    color: $black;
}
