.ui-keyboard {
    width: 100%;
    position: fixed;
    bottom: 0%;
    left: 0;
    transform: translateY(100%);
    transition: transform 300ms ease-in-out;
    padding: 20px 50px 20px 50px;
    max-height: 550px;

    .keyboard-in & {
        transform: translateY(0%);
    }

    input {
        color: lighten($black, 30%);
    }
}

.ui-keyboard-preview-wrapper input {
    border: none;
    border-radius: 0;
    background: $white;
}