.hotspot-home-start {
    display: block;
    position: absolute;
    top: 0; right: 0; bottom: 0; left: 0;
    width: 100vw;
    height: 100vh;
    @extend .visually-hidden-clickable;    
}

// .hotspot-home-evidence {
//     position: absolute;
//     top: 60px;
//     left: 320px;
//     height: 270px;
//     width: 350px;
//     @extend .visually-hidden-clickable;    
// }

// .hotspot-home-legacy {
//     position: absolute;
//     bottom: 270px;
//     right: 220px;
//     height: 250px;
//     width: 350px;
//     @extend .visually-hidden-clickable;    
// }

.hotspot-menu-about {
    position: absolute;
    bottom: 120px;
    left: 310px;
    height: 200px;
    width: 200px;
    @extend .visually-hidden-clickable;    

    @media #{$BPG}{
        bottom: 200px;
        left: 480px;
        height: 270px;
        width: 310px;
    }
}

.hotspot-menu-evidence {
    position: absolute;
    bottom: 120px;
    left: 540px;
    height: 200px;
    width: 200px;
    @extend .visually-hidden-clickable;
    
    @media #{$BPG}{
        bottom: 200px;
        left: 820px;
        height: 270px;
        width: 310px;    
    }
}

.hotspot-menu-legacy {
    position: absolute;
    bottom: 120px;
    right: 310px;
    height: 200px;
    width: 200px;
    @extend .visually-hidden-clickable;

    @media #{$BPG}{
        bottom: 200px;
        right: 450px;
        height: 270px;
        width: 310px;
    } 
}

.footer-hotspots {
    position: absolute;
    bottom: 0;
    left: 0;

    span {
        visibility: hidden;
        text-indent: -9999px;
    }
}

.hotspot-footer-home {
    position: absolute;
    bottom: 120px;
    left: 90px;

    // @media #{$BPG}{
    //     height: 70px;
    //     width: 70px;
    //     bottom: 140px;
    //     left: 140px;
    // }

    @media #{$BPG}{
        height: 70px;
        width: 70px;
        bottom: 180px;
        left: 150px;
    }
}


.hotspot-footer-form {
    position: absolute;
    bottom: 120px;
    left: 160px;

    @media #{$BPG}{
        height: 70px;
        width: 70px;
        bottom: 180px;
        left: 250px;
    }
}

.hotspot-footer-about {
    position: absolute;
    bottom: 40px;
    left: 60px;

    @media #{$BPG}{
        height: 70px;
        width: 70px;
        bottom: 60px;
        left: 110px;
    }
}

.hotspot-footer-evidence {
    position: absolute;
    bottom: 40px;
    left: 125px;

    @media #{$BPG}{
        height: 70px;
        width: 70px;
        bottom: 60px;
        left: 200px;
    }
}

.hotspot-footer-legacy {
    position: absolute;
    bottom: 40px;
    left: 190px;

    @media #{$BPG}{
        height: 70px;
        width: 70px;
        bottom: 60px;
        left: 290px;
    }
}

.hotspot {
    opacity: 0 !important;
}

